const redirectPopin = (locale: string) => {
    return {
        show: false,
        language: navigator.language,
        onDeclineRedirection() {
            this.show = false
            sessionStorage.setItem('declinedRedirection', 'true')
        },
        init(): boolean | void {
            const declinedRedirection = sessionStorage.getItem("declinedRedirection")
            if (!declinedRedirection) {
                if (['fr-CA', 'en-US'].some((l) => l === navigator.language && locale !== l.replace('-', '_') )) {
                    this.show = true
                }
            }
        }
    }
}

(window as any).redirectPopin = redirectPopin
