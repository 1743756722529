import Ready from '@/utils/ready';

const selector = '[data-ref="imageLightbox"]';

class ImageLightbox {
    public el: HTMLElement;
    public id: any;
    public images: any;
    // public lightboxBlock: any;
    public lightboxEl: any;
    public lightboxBackground: any;
    public lightboxClose: any;
    public lightboxSlidesContainer: any;
    public lightboxSlides: any;
    public lightboxSlideIndex: number;
    public lightboxSlidePrev: any;
    public lightboxSlideNext: any;
    public lightboxCaption: any;
    // public lightboxThumbnailsContainer: any;
    // public lightboxThumbnails: any;
    // public backgroundedImages: any;

    constructor(element: HTMLElement) {
        this.el = element;
        this.id = element.dataset.lightboxId;
        this.images = document.querySelectorAll(`[data-lightbox-image-id="${this.id}"]`);
        // this.lightboxBlock = this.el.querySelector(`[data-ref="imageLightboxBlock"][data-lightbox-id="${this.id}"]`);
        this.lightboxEl = this.el.querySelector(`[data-ref="imageLightboxWrapper"][data-lightbox-id="${this.id}"]`);
        this.lightboxBackground = this.lightboxEl.querySelector('.imageLightbox__background');
        this.lightboxClose = this.lightboxEl.querySelector('.imageLightbox__close');
        this.lightboxSlidesContainer = this.lightboxEl.querySelector('.imageLightbox__slides');
        this.lightboxSlideIndex = 1;
        this.lightboxSlidePrev = this.lightboxEl.querySelector('.imageLightbox__slides__prev');
        this.lightboxSlideNext = this.lightboxEl.querySelector('.imageLightbox__slides__next');
        this.lightboxCaption = this.lightboxEl.querySelector('#imageLightbox__caption');
        // this.lightboxThumbnailsContainer = this.lightboxEl.querySelector('.imageLightbox__thumbnails');
        // this.images = this.lightboxBlock.querySelectorAll('img');
        // this.backgroundedImages = this.lightboxBlock.querySelectorAll('[data-lightbox="bgImageGallery"]');
        if (this.images.length > 0) {
            this.initElements();
            this.lightboxSlides = this.lightboxEl.querySelectorAll('.imageLightbox__slides__slide');
            // this.lightboxThumbnails = this.lightboxEl.querySelectorAll('.imageLightbox__thumbnails__thumbnail');
            this.initSlide();
            this.addEventListeners();
        }
    }

    private initElements() {
        // this.createElements(this.lightboxSlidesContainer, this.images, 'imageLightbox__slides__slide', 'imageLightbox__slides__slide__image');
        // this.createElements(this.lightboxThumbnailsContainer, this.images, 'col imageLightbox__thumbnails__thumbnail');
        // ([this.backgroundedImages, this.images] as any).forEach((items: any) => {
        //     items.forEach((item: any) => {
        //         if (item.style.backgroundImage) {
        //             this.createElement(item.style.backgroundImage.slice(5, -2));
        //         } else {
        //             this.createElement(item.src);
        //         }
        //     });
        // });

        const images = Array.from(this.images);
        images.forEach((imageEl: any) => this.createElement(imageEl.dataset.lightboxImage));
        if (images.length <= 1) {
            this.lightboxSlidePrev.style.display = 'none';
            this.lightboxSlideNext.style.display = 'none';
        }
    }

    // private createElements(container: any, images: any, imgContainerClass: string, imgClass: string = '') {
        // [].forEach.call(images, (image: any) => {
        //     const imgContainer = document.createElement('div');
        //     imgContainer.className = imgContainerClass;
        //     const img = `<img data-object-fit data-ref="objectFit" class="${imgClass}" src="${image.src}" alt="${image.alt}">`;
        //     imgContainer.innerHTML = img;
        //     container.appendChild(imgContainer);
        // });
    // }

    private createElement(image: string) {
        const imgContainer = document.createElement('div');
        imgContainer.className = 'imageLightbox__slides__slide';
        const img = `<img data-object-fit="contain" data-ref="objectFit" class="imageLightbox__slides__slide__image" src="${image}">`;
        imgContainer.innerHTML = img;
        this.lightboxSlidesContainer.appendChild(imgContainer);
    }

    private initSlide() {
        this.lightboxSlides[this.lightboxSlideIndex - 1].style.display = 'block';
    }

    private addEventListeners() {
        // [].forEach.call(this.images, (image: any, index: any) => {
        //     image.addEventListener('click', (e: any) => {
        //         this.openLightbox();
        //         this.currentSlide(index + 1);
        //     });
        // });
        // let array: any = [];
        // ([this.backgroundedImages, this.images] as any).forEach((items: any) => {
        //     items.forEach((item: any) => {
        //         array = [...array, item];
        //     });
        // });
        Array.from(document.querySelectorAll(`[data-lightbox-image-id="${this.id}"]`)).forEach((item: any, index: number) => {
            item.addEventListener('click', (e: any) => {
                this.openLightbox();
                this.currentSlide(index + 1);
            });
        });
        this.lightboxClose.addEventListener('click', (e: any) => {
            this.closeLightbox();
        });
        this.lightboxSlidePrev.addEventListener('click', (e: any) => {
            this.switchSlide(-1);
        });
        this.lightboxSlideNext.addEventListener('click', (e: any) => {
            this.switchSlide(1);
        });
        this.lightboxEl.addEventListener('click', (e: any) => {
            if (e.target === this.lightboxBackground) {
                this.closeLightbox();
            }
        });
        // [].forEach.call(this.lightboxThumbnails, (thumbnail: any, index: any) => {
        //     thumbnail.addEventListener('click', (e: any) => {
        //         this.currentSlide(index + 1);
        //     });
        // });
    }

    private openLightbox() {
        this.lightboxEl.style.display = 'flex';
    }

    private closeLightbox() {
        this.lightboxEl.style.display = 'none';
    }

    // Next/previous controls
    private switchSlide(n: any) {
        this.showSlides(this.lightboxSlideIndex += n);
    }

    // Thumbnail image controls
    private currentSlide(n: any) {
        this.showSlides(this.lightboxSlideIndex = n);
    }

    private showSlides(n: any) {
        let i: any;
        if (n > this.lightboxSlides.length) {
            this.lightboxSlideIndex = 1;
        } else if (n < 1) {
            this.lightboxSlideIndex = this.lightboxSlides.length;
        }
        for (i = 0; i < this.lightboxSlides.length; i++) {
            this.lightboxSlides[i].style.display = 'none';
        }
        // for (i = 0; i < this.lightboxThumbnails.length; i++) {
        //     this.lightboxThumbnails[i].className = this.lightboxThumbnails[i].className.replace('imageLightbox__thumbnails__thumbnail--active', '');
        // }
        this.lightboxSlides[this.lightboxSlideIndex - 1].style.display = 'block';
        // this.lightboxThumbnails[this.lightboxSlideIndex - 1].className += ' imageLightbox__thumbnails__thumbnail--active';
        // this.lightboxCaption.innerHTML = this.lightboxSlides[this.lightboxSlideIndex - 1].querySelector('img').alt;
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new ImageLightbox(element);
    });
})();
