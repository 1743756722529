import HomeSlider from "@/components/organisms/HomeSlider.vue";
import Ready from '@/utils/ready';
import Vue from 'vue';
import axios from "axios";

(() => {
    Ready.watch('[data-ref="homeSlider"]', (element: HTMLElement) => {
        axios.get((element as any).dataset.apiPath)
            .then((res) => {
                if (res.data.length > 0) {
                    new Vue({
                        components: {
                            HomeSlider,
                        },
                        template: `<HomeSlider jsonSlides='${JSON.stringify(res.data)}' />`,
                    }).$mount(element);
                }
            })
            .catch((err) => {throw new Error(err)});
    });
})();
