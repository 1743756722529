import Ready from "@/utils/ready";

const selector = '[data-ref="marketLineButton"]';

class MarketLines {
    private el: HTMLElement;
    private id: string | undefined;
    private line: HTMLElement | null;

    constructor(element: HTMLElement) {
        this.el = element;
        this.id = this.el.dataset.id;
        this.line = document.querySelector(`[data-ref="marketLine"][data-id="${this.id}"]`);
        if (this.line) {
            this.init();
        }
    }

    private init() {
        this.el.addEventListener('click', (e: any) => {
            this.removeActive(() => {
                this.el.classList.add('active');
                (this.line as any).classList.add('active');
            });
        });
    }

    private removeActive(callback: any) {
        const elements = [selector, '[data-ref="marketLine"]'];
        elements.forEach((element: any) => {
            const items = document.querySelectorAll(element);
            items.forEach((item: any) => {
                item.classList.remove('active');
            });
        });
        callback();
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new MarketLines(element);
    });
})();
