import Ready from "@/utils/ready";
import uuid from "uuid";
const selector = '[data-ref="accordion"]';

class Accordion {
    public el: HTMLElement;
    private id: any;
    constructor(element: HTMLElement) {
        this.el = element;
        this.id = element.dataset.id;
        this.init();
    }
    private init() {
        const head = this.el.querySelector(`[data-ref="accordionHead"][data-id="${this.id}"]`);
        if (head) {
            (head as any).addEventListener('click', (e: any) => {
                this.el.classList.toggle('accordion--open');
            });
        }
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Accordion(element);
    });
})();
