const axios = require("axios");

let btn = document.getElementById("cookie-btn");
let cookieDiv = document.getElementById("cookies");
let footer = document.querySelector('#cookiewrapper');
if (btn) {
  btn.onclick = function() {
    axios
      .get("/fr/cookie")
      .then(function() {
            cookieDiv.remove();
            footer.className = '';
      })
  };
}
