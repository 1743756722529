import Ready from '@/utils/ready';
import 'objectFitPolyfill/dist/objectFitPolyfill.min.js';

const selector = '[data-ref="objectFit"]';

const init = (el: any = null) => {
    (window as any).objectFitPolyfill(el);
};

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        if (element && !(element as any).parentNode.classList.contains('object-fit-polyfill')) {
            init(element);
        }
    });
})();

export default init;
