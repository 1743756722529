// @ts-ignore
import Ready from "@/utils/ready";
// @ts-ignore
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

const selector = 'input[type="file"][data-ref="filepond"]';

Ready.watch(selector, (element: HTMLInputElement) => {
  FilePond.registerPlugin(FilePondPluginImagePreview);
  FilePond.registerPlugin(FilePondPluginFileValidateType);

  const options = JSON.parse(element.getAttribute('data-test') || '{}');
  const text = (window as any).getFileUploadTrans();

  const pond = FilePond.create(element, {
    ...options,
    acceptedFileTypes: ['image/png', 'image/png', 'image/jpeg', 'image/jpg', 'video/mp4'],
    storeAsFile: true,
    labelIdle: `<div class="flex lg:flex-row flex-col gap-4 items-center justify-center">
      <span class="block text-secondary text-xs trailing-sm">${ text ? text : '-' }</span>
      <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.33325 18.8827V31.8456C2.33325 32.9916 2.7885 34.0907 3.59884 34.901C4.40918 35.7113 5.50824 36.1666 6.65423 36.1666H31.3472C32.4932 36.1666 33.5922 35.7113 34.4026 34.901C35.2129 34.0907 35.6682 32.9916 35.6682 31.8456V18.8827M19.4629 2.83325V28.7592M19.4629 28.7592L11.9011 21.1974M19.4629 28.7592L27.0246 21.1974" stroke="#332E72" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>`,
  });

  (element as any)._APP_POND = pond;
});
