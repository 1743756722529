// @ts-ignore
import Ready from '@/utils/ready';
import {find} from 'lodash';
const selector = '[data-ref="imageSlider"]';

class imageSlider {
    private el: HTMLElement;
    private id: string|undefined;
    private bulletsEl: any[] = [];
    private slidesEl: any[] = [];
    private currentSlide: number = 1;
    private slideInterval: number = 3000;
    private interval: any = null;
    constructor(el: any) {
        this.el = el;
        this.id = this.el.dataset['refId'];
        this.slidesEl = Array.from(this.el.querySelectorAll(`[data-ref="imageSliderSlide"][data-ref-id="${this.id}"]`));
        this.bulletsEl = Array.from(this.el.querySelectorAll(`[data-ref="imageSliderBullet"][data-ref-id="${this.id}"]`));
        this.initialize();
    }

    private initialize() {
        this.setCurrentSlide(this.currentSlide);
        this.addEventListeners();
        this.setSlideInterval();
    }

    private setSlideInterval() {
        this.interval = setInterval(() => {
            const nextSlide = this.currentSlide + 1 > this.slidesEl.length ? 1 : this.currentSlide + 1;
            this.setCurrentSlide(nextSlide);
        }, this.slideInterval);
    }

    private clearSlideInterval() {
        if (this.interval) {
            clearInterval(this.interval);
            this.setSlideInterval();
        }
    }

    private addEventListeners() {
        this.bulletsEl.forEach((bulletEl: HTMLElement) => {
           bulletEl.addEventListener('click', this.onBulletClick.bind(this));
        });
    }

    private onBulletClick(e: any) {
        const el = e.target;
        this.setCurrentSlide(Number(el.dataset['bulletId']));
    }
    
    private setCurrentSlide(slideNb: number) {
        this.currentSlide = slideNb;
        this.slidesEl.forEach((slideEl: HTMLElement) => {
            const bullet = find(this.bulletsEl, (bulletEl) => bulletEl.dataset['bulletId'] === slideEl.dataset['slideId']);
           if (Number(slideEl.dataset['slideId']) === slideNb) {
               bullet.classList.add('imageSlider_bullet--active');
               return slideEl.style.display = 'block';
           }
            bullet.classList.remove('imageSlider_bullet--active');
           return slideEl.style.display = 'none';
        });
        this.clearSlideInterval();
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        return new imageSlider(element);
    });
})();
