import Ready from "@/utils/ready";

const selector = '[data-ref="alert"]';

class Alert {
    el: any;
    id: any;
    closeButton: any;
    constructor(element: any) {
        this.el = element;
        this.id = this.el.dataset.id;
        this.closeButton = this.el.querySelector(`[data-ref="alertCloseButton"][data-id="${this.id}"]`);
        this.addEventListener();
    }

    private addEventListener() {
        this.closeButton.addEventListener('click', () => {
            this.el.remove();
        });
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Alert(element);
    });
})();
