import Ready from "@/utils/ready";
// @ts-ignore
import Choices from "choices.js";

const selector = "[data-ref='select']";

(() => {
  Ready.watch(selector, (element: any) => {
    let options = {};
    if (element.dataset.options) {
      options = JSON.parse(element.dataset.options);
    }

    new Choices(element, {
      searchEnabled: false,
      itemSelectText: "",
      ...options,
    });
  });
})();

