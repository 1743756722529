
    import {Component, Prop, Vue} from 'vue-property-decorator';
    @Component
    export default class HomeSlider extends Vue {
        @Prop({default: []}) private jsonSlides: any;
        private slides: any = [];
        private currentSlideNumber: number = 0;
        private slideIntervalTime: number = 7500;
        private slideInterval: any = null;
        private setCurrentSlideNumber(slideNb: number) {
            this.currentSlideNumber = slideNb;
            clearInterval(this.slideInterval);
            this.setInterval();
        }

        mounted() {
            this.slides = JSON.parse(this.jsonSlides);
          console.log(this.slides);
            this.setInterval();
        }

        private setInterval() {
            this.slideInterval = setInterval(this.nextSlide, this.slideIntervalTime);
        }

        private nextSlide() {
            if (this.slides) {
                if (this.currentSlideNumber + 1 > this.slides.length - 1) {
                    this.currentSlideNumber = 0;
                } else {
                    this.currentSlideNumber += 1;
                }
            }
        }

        private get currentSlide() {
            if (this.slides && this.slides.length > 0) {
                return this.slides[this.currentSlideNumber];
            }
            return null;
        }
    }
