class FormLabel {
    private el: any;
    private hasValue: boolean;

    constructor(element: HTMLElement) {
        this.el = element;
        this.hasValue = false;
        this.addEvents();
    }

    private addEvents() {
        const formGroup = this.el.closest('.form-group');
        this.el.addEventListener('focus', (e: any) => {
            formGroup.classList.add('selected');
        });
        this.el.addEventListener('blur', (e: any) => {
            formGroup.classList.remove('selected');
        });
    }
}

export default FormLabel;
