import 'clampify';
import Ready from '@/utils/ready';

const selector = '[data-ref="clampify"]';
declare const $clampify: any;

const init = (el: any = null) => {
    const lines = el.dataset.lines || 3;
    ($clampify as any)(el, {
        maxLines: lines,
        endsWith: '...',
        autoUpdate: true,
    });
};

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        init(element);
    });
})();
