export default class Scroller {
    el: any;
    offset: number = 80;
    isIE11: any;

    constructor(element: HTMLElement, offset: number) {
        this.el = element;
        this.offset = offset;
        this.isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
    }

    public scrollTo() {
        const y = (this.el.getBoundingClientRect().top + document.documentElement.scrollTop) - this.offset;
        if (this.isIE11) {
            window.scrollTo(0, y);
        } else {
            window.scrollTo({
                top: y,
                behavior: 'smooth',
            });
        }
    }
}

(window as any).Scroller = Scroller;
