// @ts-ignore
import Swiper from 'swiper/bundle';
// import Ready from '@/utils/ready';
// @ts-ignore
import Ready from "@/utils/ready";
// import 'swiper/css';

const selector = '[data-ref="slider"]';

// class Slider {
//   el: any;
//   instance: any = null;
//
//   constructor(el: any, options = {}) {
//     this.instantiate(this.el, options);
//   }
//
//   private instantiate(el: any, options: any) {
//     this.instance = new Swiper(el, {
//       observer: true,
//       observeParents: true,
//       ...options,
//     });
//   }
// }

Ready.watch(selector, (element: HTMLElement) => {
  const option = element.dataset.option ? JSON.parse((element.dataset.option as any)) : {};
  const buttonNext: any = element.querySelector(`[data-ref="slider-button-next"]`);
  const buttonPrev: any = element.querySelector(`[data-ref="slider-button-prev"]`);

  return new Swiper(element, {
    navigation: buttonNext && buttonPrev ? {
        nextEl: `[data-ref="slider-button-next"][data-id="${ buttonNext.dataset.id }"]`,
        prevEl: `[data-ref="slider-button-prev"][data-id="${ buttonPrev.dataset.id }"]`,
      }
      : false,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    threshold: 10,
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'custom',
    //   renderCustom: () => {
    //     return `<div>Bonjour</div>`;
    //   },
    // },
    ...option,
  });
});
