import Ready from '@/utils/ready';
import Scroller from '@/js/scroller';
import FormLabel from "@/js/form-label";
const selector = '[data-ref="form"]';

class FormValidator {
    el: any = null;
    inputs: any = [];
    errorDivName: string = 'form-error';

    constructor(el: any) {
        this.el = el;
        this.el.setAttribute('novalidate', '');
        this.findInput();
        this.watchInput();
        this.watchSubmit();
    }

    private findInput() {
        [].forEach.call(this.el.querySelectorAll('input'), (input: any) => {
            this.inputs = [...this.inputs, input];
        });
        [].forEach.call(this.el.querySelectorAll('select'), (select: any) => {
            this.inputs = [...this.inputs, select.closest('.choices')];
        });
        [].forEach.call(this.el.querySelectorAll('textarea'), (textarea: any) => {
            this.inputs = [...this.inputs, textarea];
        });
    }

    private watchInput() {
        [].forEach.call(this.inputs, (input: any) => {
            if (input.classList.contains('choices')) {
                input.querySelector('[data-ref="select"]').addEventListener('change', () => {
                    this.initError(input);
                });
            } else {
                input.addEventListener('input', () => {
                    this.initError(input);
                });
            }
            new FormLabel(input);
        });
    }

    private initError(input: any) {
        const parentHasError = input.parentNode.querySelector(`.${this.errorDivName}`) || input.parentNode.querySelector('.list--error');
        if (parentHasError) {
            input.classList.remove('input--error');
            parentHasError.remove();
        }
        if (input.classList.contains('choices')) {
            const inputSelect = input.querySelector('[data-ref="select"]');
            if (!inputSelect.validity.valid) {
                input.classList.add('input--error');
                this.createError(input);
            }
        } else if (!input.validity.valid) {
            input.classList.add('input--error');
            this.createError(input);
        }
    }

    private createError(input: any) {
        const error = document.createElement('div');
        error.innerHTML = input.classList.contains('choices') ? input.querySelector('[data-ref="select"]').validationMessage : input.validationMessage;
        error.classList.add(this.errorDivName);
        input.parentNode.append(error);
    }

    private watchSubmit() {
        this.el.addEventListener('submit', (e: any) => {
            [].forEach.call(this.inputs, (input: any) => {
                if (input.classList.contains('choices')) {
                    const inputSelect = input.querySelector('[data-ref="select"]');
                    if (!inputSelect.validity.valid) {
                        e.preventDefault ? e.preventDefault() : e.returnValue = false;
                        this.initError(input);
                        new Scroller(this.el.querySelector('.input--error'), 200).scrollTo();
                    }
                } else {
                    if (!input.validity.valid) {
                        this.initError(input);
                        e.preventDefault ? e.preventDefault() : e.returnValue = false;
                        new Scroller(this.el.querySelector('.input--error'), 200).scrollTo();
                    }
                }
            });
        });
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new FormValidator(element);
    });
})();
