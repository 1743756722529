import {defaultStyle} from '@/js/map.style';
import {Loader} from 'google-maps';

type Marker = google.maps.Marker & {
  title: string,
  el: HTMLImageElement & {
    isSelected: boolean, 
    updatePopup: () => void
  }
}; 

type MarkerOptions = {
  id: number,
  lat: number,
  lng: number,
  selected: boolean,
  title: string,
};

const mapEl = document.querySelector('[data-ref="custom-map"]') as HTMLElement;

let infoWindow: google.maps.InfoWindow;

if (mapEl) {
  const popupEls = [...mapEl.querySelectorAll('[data-popup]')] as HTMLElement[];
  const options = JSON.parse(mapEl.dataset.options!);
  const parameters = {
    popup: true,
    zoom: true,
    ...JSON.parse(mapEl.dataset.parameters!),
  }
  const {markerIcon, selectedMarkerIcon} = JSON.parse(mapEl.dataset.bridge!);
  const markers = JSON.parse(mapEl.dataset.markers!) as MarkerOptions[];
  const geojson = mapEl.dataset.geojson && JSON.parse(mapEl.dataset.geojson);

  const createMap = async () => {
    const loader = new Loader('AIzaSyCeOgpXC7GVLNbYBS_n61KzrroDjTrY40A');
    const google = await loader.load();

    const style = new google.maps.StyledMapType(defaultStyle as any);

    const map = new google.maps.Map(mapEl.querySelector('[data-map]') as HTMLDivElement, {
      ...options,
    });
    const {setFillStyle} = applyStyle(map);

    map.mapTypes.set('styled_map', style);
    map.setMapTypeId('styled_map');
    map.addListener("dragend", () => map.setCenter(map.getCenter()));
    mapEl.addEventListener('marker:select', (e: any) => {
      if (!e.detail.id) {
        setFillStyle('#332E72');
      }
    })
    return map;
  }

  function applyStyle(map: google.maps.Map){
    return {
      setFillStyle: (color: string) => {
        map.data.revertStyle();
        map.data.setStyle({
          fillColor: color,
          strokeWeight: 1,
          strokeColor: color,
          fillOpacity: 1,
        });
      },
      setBaseStyle: () => {
        map.data.revertStyle();
        map.data.setStyle({
          fillColor: '#e0f7ff',
          strokeWeight: 1,
          strokeColor: '#65acbd',
          fillOpacity: 1
        })
      },
      setHighlightStyle: (feature: any) => {
        map.data.revertStyle();
        map.data.overrideStyle(feature, {
          fillColor: '#332E72',
        });
      }
    }
  }
    

  const addLayers = async (map: google.maps.Map) => {
    const {setFillStyle} = applyStyle(map);

    const response = await fetch(geojson);
    const data = await response.json();

    map.data.addGeoJson(data);
    setFillStyle('#332E72');
  }

  const addMarkers = async (map: google.maps.Map) => {

    const markersInstances = markers.map((m) => {

      const {lat, lng, title, id} = m;
      
      const marker = new google.maps.Marker({ 
        position: { lat, lng },
        title,
        icon: markerIcon,
        map: map,
      }) as Marker;
      const {setFillStyle} = applyStyle(map);

      marker.addListener('click', (e: google.maps.MapMouseEvent) => {
        let target = e.domEvent.target as Marker['el'];

        if (target.tagName !== 'IMG') {
          target = target.children[0] as Marker['el'];
        }

        marker.el = target;

        if (parameters.popup) {
          const popup = popupEls.find((el) => parseFloat(el.id) === id)!

          if (marker.el?.isSelected) {
            infoWindow.close();
          } else {
            infoWindow.setContent(popup.outerHTML); 
            infoWindow.open(map, marker);
          }
        }

        if (marker.el?.isSelected) {
          unselectMarker(marker);
          mapEl.dispatchEvent(new CustomEvent('marker:select', {
            detail: {id: null}
          }))
        } else {

          setFillStyle('transparent');
          selectMarker(marker);
          mapEl.dispatchEvent(new CustomEvent('marker:select', {
            detail: {id}
          }))
        }
      });

      const selectMarker = (marker: Marker) => {

        markersInstances.forEach((m) => {
          m.el?.isSelected && unselectMarker(m);
        })

        const {setBaseStyle, setHighlightStyle} = applyStyle(map);

        marker.el.isSelected = true;
        marker.el.src = selectedMarkerIcon; 

        const coord = new google.maps.LatLng(marker.getPosition()!.lat(), marker.getPosition()!.lng()); 
        const checkInside = (p: any, f: any) => {
          const polygon = new google.maps.Polygon({ paths: p });
          const isInside = google.maps.geometry?.poly.containsLocation(coord, polygon);
          if (isInside) {
            setBaseStyle();
            setHighlightStyle(f);
          }
        }

        map.data.forEach((feature: any) => {
          const type = feature.getGeometry().getType();
          const geometry = feature.getGeometry().getArray();
          if (type === 'Polygon') {
            const coordsArray = geometry[0].getArray();
            checkInside(coordsArray, feature);

          } else if (type === 'MultiPolygon') {
            geometry.forEach((polygon: any) => {
              polygon.g.forEach((coordinates: any) => {
                const coordsArray = coordinates.getArray();
                checkInside(coordsArray, feature);
              })
            });            
          }
        });

        if (parameters.zoom) {
          setTimeout(() => {
            map.setCenter(marker.getPosition()!);
            map.setZoom(6);
          }, 100)
        }
      }
    
      const unselectMarker = (marker: Marker) => {
        const {setFillStyle} = applyStyle(map);

        marker.el.isSelected = false;
        marker.el.src = markerIcon;

        map.setZoom(options.zoom!);

        // setFillStyle('#332E72');

        setFillStyle('transparent');
      };

      return marker;
    });

    return markersInstances;
  }

  const waitTill = (selector: string): Promise<HTMLElement> => {
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector) as HTMLElement);
      }

      const observer = new MutationObserver(_ => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector) as HTMLElement);
          observer.disconnect();
        }
      });

      observer.observe(mapEl, {
        childList: true,
        subtree: true
      });
    });
  }

  const createInfoWindow = () => 
    new google.maps.InfoWindow({
      // @ts-ignore
      minWidth: 300
    });

  (async () => {
    const map = await createMap();
    if (geojson) await addLayers(map);

    infoWindow = createInfoWindow();
    const markerInstances = await addMarkers(map);

    markers.forEach((marker) => {
      if (marker.selected) {
        waitTill(`[title="${marker.title}"]`).then((el: HTMLElement) => {
          const selectedMarker = markerInstances.find((m) => m.title === marker.title)!;
          google.maps.event.trigger(selectedMarker, 'click', {domEvent: {target: el}});

          // idk why but it doesn't work without timeout

          setTimeout(() => {
            el.children[0].setAttribute('src', selectedMarkerIcon);
          }, 500);
        });
      }
    });
  })()
}
