import Ready from "@/utils/ready";
import axios from 'axios';

const selector = '[data-ref="itemsFilters"]';

class ProductFilter {
    private el: HTMLElement;
    private url: any = '';
    private query: any = {
        market: '',
        productCategory: '',
        product: '',
        technologyCategory: '',
        technology: '',
    };
    private container: any = null;
    private inputs: any;
    private inputsChecked: any;
    private type: any;

    constructor(element: HTMLElement) {
        this.el = element;
        this.url = this.el.dataset.url;
        this.container = document.querySelector('[data-ref="filterSection_filters"]');
        this.inputs = Array.from(this.container.querySelectorAll('input[type="radio"]'));
        this.inputsChecked = Array.from(this.container.querySelectorAll('input[type="radio"]:checked'));
        this.type = (window as any).marketFilterType;
        this.resetQuery();
        this.init();
    }

    private init() {
        this.assignQuery();
        this.container.addEventListener('click', (e: any) => {
            if (this.inputs.includes(e.target)) {
                this.checkInputs(e.target);
                this.selectInputs();
                this.assignQuery();
            }
        });
    }

    private checkInputs(currentInput: any) {
        const {lvl, sector} = currentInput.dataset;
        if (currentInput.dataset.selected && currentInput.dataset.selected === 'true') {
            this.removeSelectedElement(currentInput);
        }
        this.inputs.filter((input: any) => input !== currentInput).forEach((input: any) => {
            if (input.dataset.lvl === lvl && input.dataset.sector === sector) {
                this.removeSelectedElement(input);
            }
        });
        this.inputsChecked = Array.from(this.container.querySelectorAll('input[type="radio"]:checked'));
    }

    private removeSelectedElement(input: any) {
        const closest = input.closest('[data-ref="filter_item"]');
        closest.classList.remove('selected');
        input.dataset.selected = false;
        closest.querySelectorAll('input[type="radio"]:checked').forEach((childInput: any) => {
            childInput.closest('[data-ref="filter_item"]').classList.remove('selected');
            childInput.checked = false;
            childInput.dataset.selected = false;
        });
    }

    private selectInputs() {
        this.inputs.forEach((input: any) => {
            const parent = input.closest('[data-ref="filter_item"]');
            if (input.checked) {
                parent.classList.add('selected');
                input.dataset.selected = true;
            }
        });
    }

    private assignQuery() {
        this.resetQuery();
        this.inputsChecked.forEach((input: any) => {
            if (input.name === 'product') {
                this.query.product = input.dataset.slug;
            } else if (input.name === 'productCategory') {
                this.query.productCategory = input.dataset.slug;
            } else if (input.name === 'solutionCategory') {
                this.query.technologyCategory = input.dataset.slug;
            } else if (input.name === 'solution') {
                this.query.technology = input.dataset.slug;
            } else if (input.name === 'market') {
                this.query.market = input.dataset.slug;
            }
        });
        this.sendRequest();
    }

    private resetQuery() {
        const queries = Object.keys(this.query);
        queries.forEach((key: any) => {
            this.query[key] = '';
        });
        this.query.market = this.el.dataset.market;
        this.query.technologyCategory = this.el.dataset.solutionCategory;
        this.query.productCategory = this.el.dataset.productCategory;
    }

    private sendRequest() {
        const {market, productCategory, product, technologyCategory, technology} = this.query;
        axios.post(`${this.url}?market=${market}&productCategory=${productCategory}&product=${product}&solutionCategory=${technologyCategory}&solutionGroup=${technology}&type=${this.type}`)
            .then((res) => {
                const target = document.querySelector('[data-ref="filtersResult"]');
                const resultNumber = document.querySelector('[data-ref="filterSection_resultNumber"]');
                const resultNumberWord = document.querySelector('[data-ref="filterSection_resultNumber_word"]');
                (target as any).innerHTML = res.data.content;
                (resultNumber as any).innerHTML = res.data.resultLenght;
                (resultNumberWord as any).innerHTML = res.data.resultLenght <= 1 ? JSON.parse((resultNumberWord as any).dataset.key).result : JSON.parse((resultNumberWord as any).dataset.key).results;
            });
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new ProductFilter(element);
    });
})();




